import React, { useState } from 'react'
import dynamic from 'next/dynamic'
import { emailValidator } from '@csc/dls/utils'
import allPromisesWithRetries from '@/helpers/allPromisesWithRetries'

const Row = dynamic(import('@csc/dls/Row'))
const Col = dynamic(import('@csc/dls/Col'))
const Text = dynamic(import('@csc/dls/Text'))

const PrimaryButton = dynamic(import('@csc/dls/PrimaryButton'))

const AlphanumericInput = dynamic(import('@csc/dls/AlphanumericInput'))

const ExclusionsApplyModal = dynamic(import('@/components/Sitewide/Footer/ExclusionsApplyModal'))

const Subscription = () => {
  const [subscribe, setSubscribe] = useState<boolean>(false)
  const [exclusionApplyModal, setExclusionApplyModal] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const [email, setEmail] = useState<string | ''>('')

  const validateEmail = (emailToValidate: string) => (
    emailToValidate && emailValidator.pattern.value.test(emailToValidate)
      ? ''
      : 'Please enter a valid email address'
  )

  const trackSubscription = (userEmail : string) => {
    const dataLayerEvent = {
      event: 'Email Sign Ups - Footer',
      customerEmail: userEmail,
    }
    try {
      window?.dataLayer?.push(dataLayerEvent)
    } catch (e) {
      console.error(e)
    }
  }

  const trackListrakSubscriptions = (userEmail : string) => {
    try {
      // eslint-disable-next-line no-underscore-dangle
      window?._ltk?.SCA?.Update('email', userEmail)
    } catch (err) {
      console.error('Failed to submit registration Event to Listrak', err)
    }
  }

  const trackFacebookEventRegistration = (userEmail : string) => {
    try {
      allPromisesWithRetries(() => [import('@/services/Facebook')])
        .then(([{ Facebook }]) => Facebook.completeRegistration({
          content_name: 'sign-up',
          user_email: userEmail,
          status: true,
        })).catch((err) => {
          console.error('Failed to submit registration Event to Facebook Pixel', err)
        })
    } catch (err) {
      console.error('Failed to submit registration Event to Facebook Pixel', err)
    }
  }

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.stopPropagation()
    event.preventDefault()
    const errorMessage = validateEmail(email)
    setError(errorMessage)
    if (!errorMessage) {
      trackSubscription(email)
      trackFacebookEventRegistration(email)
      setEmail('')
      setSubscribe(true)
      trackListrakSubscriptions(email)
    }
  }

  return (
    <div className="border p-4 text-center md:text-left lg:text-center" style={{ backgroundColor: '#F5F7F7' }}>
      <Row cols={2}>
        <Col span={{ sm: 2, md: 1, lg: 2 }}>
          <Text size="xl" bold>Save 20% today</Text>
          <Text>when you sign up to receive emails.</Text>
          <Text
            link
            className="w-full mt-2"
            size="sm"
            onClick={() => setExclusionApplyModal(true)}
          >
            Exclusions apply
          </Text>
        </Col>
        <Col span={{ sm: 2, md: 1, lg: 2 }}>
          <form id="emailSignUp" onSubmit={handleSubmit}>
            <Row cols={3}>
              <Col span={{ sm: 2, md: 2, lg: 3 }}>
                <AlphanumericInput
                  type="email"
                  label="Your Email Address"
                  showLabel={false}
                  name="emailAddress"
                  id="emailAddressWidget"
                  placeholder="Your Email Address"
                  value={email}
                  onChange={(e) => { setError(''); setEmail(e.target.value) }}
                  errorId="emailAddress-required"
                  data-testid="emailAddress"
                />
              </Col>
              <Col span={{ sm: 1, md: 1, lg: 3 }}>
                <PrimaryButton
                  htmlType="submit"
                  label="submit"
                  id="submit"
                  type={email ? 'primary' : 'disabled'}
                  data-testid="sendButton"
                >
                  Send
                </PrimaryButton>
              </Col>
            </Row>
            {error && <Text size="sm" className="text-error mt-4">{error}</Text>}
          </form>
          {subscribe
            && (
              <Text size="sm" className="mt-4">
                {'Congrats you\'re signed up! Check your inbox for your unique code to use on your next order.'}
              </Text>
            )}
        </Col>
      </Row>
      <ExclusionsApplyModal
        open={exclusionApplyModal}
        onClose={() => setExclusionApplyModal(false)}
      />
    </div>
  )
}

export default Subscription
